import { durationToISOString } from '../../utils/duration';
import { getResizedImageUrl, MODES } from '@wix/wix-vod-shared/common';
import { Channel, Video } from '../../redux/types';

export interface ChannelSeoData {
  name?: string;
  description?: string;
  thumbnailUrl?: string;
  keywords?: string[];
}

export interface VideoSeoData {
  thumbnailUrl: string;
  description: string;
  name: string;
  uploadDate: string; // YYYY-MM-DD
  duration: string; //	The duration of the video in ISO 8601 format	ISO 8601	"PT1M54S"
  contentUrl?: string;
  keywords: string[];
}

export interface SeoData extends ChannelSeoData {
  video: VideoSeoData[];
}

const getVideoKeywords = (data: Video): string[] => {
  const keywords: string[] = [];

  if (data.categories && data.categories.length) {
    keywords.push(data.categories.join(', '));
  }
  if (data.tags && data.tags.length) {
    keywords.push(data.tags.join(', '));
  }
  if (data.crew) {
    data.crew.forEach((crewItem) => {
      keywords.push(crewItem.role);
      keywords.push(crewItem.name);
    });
  }
  if (data.cast) {
    data.cast.forEach((castItem) => {
      keywords.push(castItem.role);
      keywords.push(castItem.name);
    });
  }

  return keywords;
};

const getVideoContentUrls = (data: Video) => {
  const { mediaExternUrl, playInfo } = data;
  return mediaExternUrl || playInfo?.url;
};

const getThumbnailUrl = (url: string) =>
  getResizedImageUrl({
    url,
    height: 330,
    width: 330,
    mode: MODES.fill,
  });

const getChannel = (data: Channel): ChannelSeoData => {
  const keywords: string[] = [];
  const channel = data;
  const { categories, tags } = channel.statsInfo || {};
  if (categories && categories.length) {
    keywords.push(categories.map((c) => c.value).join(', '));
  }
  if (tags && tags.length) {
    keywords.push(tags.map((c) => c.value).join(', '));
  }
  return {
    name: data.title,
    description: data.description || undefined,
    thumbnailUrl: getThumbnailUrl(data.customCoverUrl || channel.coverUrl),
    keywords,
  };
};

const getVideo = (data: Video): VideoSeoData => {
  const contentUrl = getVideoContentUrls(data);
  const thumbnailUrl = getThumbnailUrl(
    data.customCoverUrl || data.posterUrl || data.coverUrl,
  );
  return {
    name: data.title,
    thumbnailUrl,
    contentUrl,
    uploadDate: data.datePublish,
    description: data.description || data.title,
    duration: durationToISOString(data.durationSec || 0),
    keywords: getVideoKeywords(data),
  };
};

const videoHasAllRequiredProperties = (video: VideoSeoData) =>
  Boolean(
    video &&
      video.name.trim() &&
      video.thumbnailUrl &&
      video.uploadDate &&
      video.description,
  );

export interface GetSeoDataParams {
  channel?: Channel;
  videos: Video[];
}

export const getSeoData = ({ channel, videos }: GetSeoDataParams): SeoData => {
  return {
    ...(channel ? getChannel(channel) : {}),
    video: videos.map(getVideo).filter(videoHasAllRequiredProperties),
  };
};
